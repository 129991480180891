import React from "react"
import {
  ThemeProvider,
  createMuiTheme,
  responsiveFontSizes,
} from "@material-ui/core"

let theme = createMuiTheme({
  palette: {
    primary: {
      main: "#2E3F61",
    },
    secondary: {
      main: "#fff",
    },
    text: {
      primary: "#849ac5",
      secondary: "#eff2f8",
      hint: "#ACFFB9",
    },
  },
  typography: {
    h2: {
      fontSize: "2rem",
    },
    h3: {
      fontSize: "1.8rem",
      fontWeight: 300,
    },
    h4: {
      fontSize: "1.6rem",
      fontWeight: 200,
    },
    h5: {
      fontSize: "1rem",
      fontWeight: 200,
    },
    subtitle2: {
      fontWeight: 300,
    },
  },
})

theme = responsiveFontSizes(theme)

const rootWrapper = ({ element }) => (
  <ThemeProvider theme={theme}>{element}</ThemeProvider>
)

export default rootWrapper
